import { QUIZ_OPTION, QUIZ_QUESTION } from "@/utils/types.enums";

import { DOCUMENT_ASSET_TYPE } from "../../Curriculum/CourseContent/Quiz/common/types";

export interface OptionType {
  optionId: string;
  key: number;
  value?: { value: string };
  textValue?: string;
  isActive?: boolean;
}
export enum QUESTION_TYPE {
  MULTI_CHOICE = "MULTI_CHOICE",
  SINGLE_CHOICE = "SINGLE_CHOICE",
  SUBJECTIVE = "SUBJECTIVE",
  DOCUMENT_UPLOAD = "DOCUMENT_UPLOAD",
  CAREER_BUILDER_SKILLS = "CAREER_BUILDER_SKILLS",
  CAREER_BUILDER_INTEREST = "CAREER_BUILDER_INTEREST",
}
export interface OptionsPropsTypes {
  options: OptionSchema[];
  handleChange: (optionId: string) => void;
  selectedOptions: string[];
  correctOptions?: string[];
  isSubmitted: boolean;
  optionType?: QUIZ_OPTION;
}

export interface QuestionTypePropsTypes {
  question: QuestionSchema;
  isCorrect?: boolean;
  isSubmitted?: boolean;
  questionType?: QUIZ_QUESTION;
}

export interface QuestionObject {
  meta: {
    QUESTION_IMAGE: string;
  };
}

export type QuestionContent = string & QuestionObject;

export interface QuestionSchema {
  meta: { QUESTION_IMAGE: { path: string } };
  solutionIds: string[];
  questionId: string;
  questionType: QUESTION_TYPE;
  question: QuestionContent;
  options: OptionSchema[];
  description: string;
  nextQuestionId: string;
  prevQuestionId: string;
  isCorrect?: boolean;
}
export interface OptionSchema {
  value: {
    key: DOCUMENT_ASSET_TYPE;
    value: string;
  };
  nextQuestionIds: string[];
  optionId: string;
  solutionText: string;
}
