export enum DateTypes {
  "CAPSTONE_START_DATE" = "CAPSTONE_START_DATE",
  "CAPSTONE_ONBOARDING_DATE" = "CAPSTONE_ONBOARDING_DATE",
  "CAPSTONE_END_DATE" = "CAPSTONE_END_DATE",
  "CAREER_START_DATE" = "CAREER_START_DATE",
  "CAREER_END_DATE" = "CAREER_END_DATE",
  "REVIEW_DAY_START_DATE" = "REVIEW_DAY_START_DATE",
}

export enum NAMESPACE_TYPE {
  LEARNING = "LEARNING",
  CAPSTONE = "CAPSTONE",
  CAREER = "CAREER",
  REVIEW_DAY = "REVIEW_DAY",
}

export enum NODE_STATE {
  CREATED = "CREATED",
  ONGOING = "ONGOING",
  COMPLETED = "COMPLETED",
}

export enum ProfileStatus {
  COMPLETED = "COMPLETED",
  NOT_COMPLETED = "NOT_COMPLETED",
  FILLED_ONBOARDING_FORM = "FILLED_ONBOARDING_FORM",
  FILLED_EDUCATION_FORM = "FILLED_EDUCATION_FORM",
  FILLED_PROFESSION_FORM = "FILLED_PROFESSION_FORM",
  FILLED_BASIC_DETAILS = "FILLED_BASIC_DETAILS",
  FILLED_PLACEMENT_DETAILS = "FILLED_PLACEMENT_DETAILS",
}

export enum TaskSubmissionState {
  "PENDING" = "PENDING",
  "IN_REVIEW" = "IN_REVIEW",
  "REVIEWED" = "REVIEWED",
  "EXPIRED" = "EXPIRED",
  "REJECTED" = "REJECTED",
}
export enum FORM_BUTTON_TYPE {
  PREV = "PREV",
  NEXT = "NEXT",
  SUBMIT = "SUBMIT",
}

export enum QUESTION_CATEGORY {
  "TEXT_QUESTION" = "TEXT_QUESTION",
  "TEXT_IMAGE_QUESTION" = "TEXT_IMAGE_QUESTION",
}

export enum QueryState {
  UNANSWERED = "UNANSWERED",
  RESOLVED = "RESOLVED",
  ANSWERED = "ANSWERED",
}

export enum QueryFilter {
  OVERALL = "Overall",
  LEARNING = "Learning",
  CAPSTONE = "Capstone",
  CAREER = "Career",
}
export enum QuizV2State {
  LANDINGPAGE = "LANDINGPAGE",
  STARTPAGE = "STARTPAGE",
  QUESTIONPAGE = "QUESTIONPAGE",
  COMPLETEDPAGE = "COMPLETEDPAGE",
  RESULTSPAGE = "RESULTSPAGE",
  ANSWERSPAGE = "ANSWERSPAGE",
}

export enum InternalQueryStateFilter {
  ALL = "all",
  OPEN = "OPEN",
  RESOLVED = "RESOLVED",
  ANSWERED = "ANSWERED",
}

export enum NODE_ENTRY_TYPE {
  ROOT = "ROOT",
  STAGE = "STAGE",
  MILESTONE = "MILESTONE",
  COMPONENT_CONTENT = "COMPONENT_CONTENT",
  QUIZ = "QUIZ",
  COMPONENT_TASK = "COMPONENT_TASK",
  LIST = "LIST",
  LIST_ENTRY = "LIST_ENTRY",
  TOPIC = "TOPIC",
  STUDIO = "STUDIO",
  WATCH = "WATCH",
  READ = "READ",
  ASSIGNMENT = "ASSIGNMENT",
  LIVE_SESSION = "LIVE_SESSION",
  READ_STATIC = "READ_STATIC",
  COURSE_MODULE = "COURSE_MODULE",
  LESSON_READING = "LESSON_READING",
  CONTENT_CONTAINER = "CONTENT_CONTAINER",
  PARAGRAPH = "PARAGRAPH",
  IMAGE = "IMAGE",
  REFERENCE = "REFERENCE",
  DROP_DOWN_CONTENT = "DROP_DOWN_CONTENT",
  NUMBER_POINT = "NUMBER_POINT",
  BULLET_POINT = "BULLET_POINT",
  FILE_UPLOAD = "FILE_UPLOAD",
  LINK_UPLOAD = "LINK_UPLOAD",

  SUBMIT = "SUBMIT",
  VIDEO_PLAYER = "VIDEO_PLAYER",
  ACTIVITY = "ACTIVITY",
  PREPARATORY_CONTENT = "PREPARATORY_CONTENT",
  STAGE_REVIEW = "STAGE_REVIEW",
  STAGE_INTRO = "STAGE_INTRO",
  PROJECT = "PROJECT",
  ZONE = "ZONE",
  META = "META",
  VIDEO = "VIDEO",
  CAREER_SESSION = "CAREER_SESSION",
  MASTER_CLASSES = "MASTER_CLASSES",
  BREAK = "BREAK",
  ACADEMIC_LEVELLING = "ACADEMIC_LEVELLING",
}

export enum META_CONTENT_TYPE {
  MAIN_CONTENT = "MAIN_CONTENT",
  FAQ = "FAQ",
  HELPFUL_RESOURCE = "HELPFUL_RESOURCE",
}

export enum API_TYPE {
  NODE_API = "NODE_API",
  STATES_API = "STATES_API",
}

export enum TopicCategory {
  PORTFOLIO = "PORTFOLIO",
  SLOTS = "SLOTS",
  EVALUATION_DAY = "EVALUATION_DAY",
  GRADUATION_DAY = "GRADUATION_DAY",
}

export enum DocumentCategory {
  PORTFOLIO = "PORTFOLIO",
  RESUME = "RESUME",
}

export enum EvaluationListStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
}

export enum FeedbackStage {
  SLOT_BOOKED = "SLOT_BOOKED",
  SLOT_NOT_BOOKED = "SLOT_NOT_BOOKED",

  INTERVIEW_IN_PROGRESS = "INTERVIEW_IN_PROGRESS",
  INTERVIEW_COMPLETED = "INTERVIEW_COMPLETED",

  QUIZ_IN_PROGRESS = "QUIZ_IN_PROGRESS",
  QUIZ_COMPLETED = "QUIZ_COMPLETED",

  FEEDBACK_PENDING = "FEEDBACK_PENDING",
  FEEDBACK_COMPLETED = "FEEDBACK_COMPLETED",

  MISSED = "MISSED",

  ABSENT = "ABSENT",
}

export enum FilterQuesStatus {
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum FeedbackQuestionsType {
  SUBJECTIVE = "SUBJECTIVE",
  GROUPED_OPTION_QUESTION = "GROUPED_OPTION_QUESTION",
  SINGLE_CHOICE = "SINGLE_CHOICE",
  TEXT_AREA = "TEXT_AREA",
  TEXT_INPUT = "TEXT_INPUT",
  FILTER = "FILTER",
}

export enum RightSectionStatus {
  EMPTY = "EMPTY",
  NOT_EMPTY = "NOT_EMPTY",
}

export enum QUIZ_QUESTION {
  "QUIZ_QUESTION" = "QUIZ_QUESTION",
  "LEARNER_ONBOARDING_QUESTION" = "LEARNER_ONBOARDING_QUESTION",
}

export enum QUIZ_OPTION {
  LMS_QUIZ = "LMS_QUIZ",
  LEARNER_ONBOARDING_EXPANDED = "LEARNER_ONBOARDING_EXPANDED",
  LEARNER_ONBOARDING_CONTRACTED = "LEARNER_ONBOARDING_CONTRACTED",
  LEARNER_ONBOARDING_IMAGE = "LEARNER_ONBOARDING_IMAGE",
}

export enum ProfileCompletionStatus {
  BASIC_PROFILE_COMPLETED = "BASIC_PROFILE_COMPLETED",
  PROFILE_COMPLETED = "PROFILE_COMPLETED",
}
